'use strict';

var util = require('./util');
var dialog = require('./dialog');
var progress = require('./progress');

function init() {
    //ET footer Email Sign up.
    $('#email-alert-signup').on('submit', function(e) {
        e.preventDefault();
        var emailaddress = $(this).find('input').val();
        var url = $(this).attr('action');
        var filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;

        if(emailaddress == '') {
            return false;
        }
        if(!filter.test(emailaddress)) {
            $('#EmailErrorMsg, .EmailErrorMsg').addClass('error');
            $('#EmailErrorMsg, .EmailErrorMsg').text(Resources.VALIDATE_EMAIL);
            if ($('.email-success').find('.thank-msg').text() !== '') {
                $('.email-success').addClass('hide');
            }
            return false;
        }
        $('.EmailErrorMsg').removeClass('error');
        $('.EmailErrorMsg').addClass('hide');
        $('.email-success').addClass('hide');
        $.ajax({
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            url: util.appendParamsToUrl(url, {
                emailAddress: emailaddress
            }),
            success: function(data, callback){
                if(callback == "success"){
                    if($('.email-success').hasClass('hide')) {
                        $('.email-success').removeClass('hide');
                    }
                    $('.email-success').find('.thank-msg').text(data.message);
                } else {
                    if($('.EmailErrorMsg').hasClass('hide')) {
                        $('.EmailErrorMsg').removeClass('hide');
                    }
                    $('.EmailErrorMsg').text(data.message);
                }
            }
        });
    });
    
  //Email Signup Modal Window Load
    $(window).on('load', function() {
        var emailSignUpLength = $('#emailsignupform').length > 0 || $('#emailsignuppromotion').length > 0;
        $.ajax({
            dataType: 'html',
            url: util.appendParamToURL(Urls.createCookies, 'emailSignUpLength', emailSignUpLength)
        });
        if($('#emailsignupform').length > 0 && document.cookie.indexOf('modalLoad') < 0) {
            //document.cookie = 'modalLoad=1; path=/;date';
            $('#emailsignupform').dialog({
                autoOpen: true,
                width: 1100,
                modal: true,
                resizable: false,
                draggable: false,
                dialogClass: 'emailsignupform',
                position: {
                    my: 'center',
                    at: 'center',
                    of: window,
                    collision: 'flipfit'
                }
            });
        }
        
        if($('#emailsignuppromotion').length > 0) {
	        $('#emailsignuppromotion').dialog({
	            autoOpen: true,
	            modal: true,
	            dialogClass: 'emailsignuppromotion',
	            show: 'fade',
	            open: function( event, ui ) {
	            	$('body').addClass('stop-scrolling');
                    $(this).parent().focus();
	            }, 
	            close: function( event, ui ) {
	            	$('body').removeClass('stop-scrolling');
	            }
	        });
        }
        
        var $formReg = $('#RegistrationForm');
        if($formReg.length > 0) {
            util.updateStateOptions($formReg);
        }
    });
    
  //Code for Email Signup Modal
    $('#emailSubmitModal').on('click', function(e) {
        e.preventDefault();
        var email = $('#email-signup-modal').val();
        var modalForm = $('#email-signup-modalForm');
        modalForm.validate();
        if (modalForm.valid()) {
            $.ajax({
                type: 'POST',
                url: util.appendParamsToUrl(Urls.emailSignupAjax, {
                    emailAddress: email
                }),
                success: function(data) {
                    if ($('.emailsignupform').length > 0) {
                        $('.emailsignupform .ui-dialog-titlebar-close').trigger('click');
                    }
                    var dlg = dialog.create({
                        options: {
                            width: 650,
                            height: 'auto',
                            dialogClass: 'emailOnce'
                        }
                    });
                    var responseDivData = $(data).filter('.emailsignupform-content').html();
                    var emailonce = $('.emailonce').length;
                    if (emailonce < 1) {
                        $(dlg).append(responseDivData);
                    }
                    dlg.dialog('open');
                }
            });
        }
    });
    
}
exports.init = function() {
    init();
};