'use strict';

var _ = require('lodash');

var util = {
    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamToURL: function(url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },
    appendParamsToUrl: function(url, params) {
        var _url = url;
        _.each(params, function(value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    }
};

module.exports = util;