'use strict';
require('./polyfills');
var contact = require('./contact'),
    validator = require('./validator'),
    searchsuggest = require('./searchsuggest'),
    emailsignup = require('./emailsignup');

require('./search')();

window._corporate = {
    _DESKTOP: 'desktop',
    _TABLET: 'tablet',
    _MOBILE: 'mobile'
};

// if jQuery has not been loaded, load from google cdn ???
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

function setMediaType() {
    var media;
    if (window.matchMedia('screen and (min-width: 960px)').matches) {
        media = window._corporate._DESKTOP;
    } else if (window.matchMedia('screen and (min-width: 768px) and (max-width: 960px)').matches) {
        media = window._corporate._TABLET;
    } else if (window.matchMedia('screen and (max-width: 767px) and (orientation: landscape)').matches) {
        media = window._corporate._MOBILE;
    } else {
        media = window._corporate._MOBILE
    }
    window._corporate.mediaType = media;
}

function createEvent(eventType) {
    var evt;
    if (typeof(Event) === 'function') {
        evt = new Event(eventType,{'bubbles':true, 'cancelable':true});
    } else { // IE
        evt = document.createEvent('Event');
        evt.initEvent(eventType, true, true);
    }
    return evt;
}


var app = {
    init: function() {
    	
    	// Header
    	setMediaType();

    	$('.mobile-menu-toggle').click(function() {
    		toggleMenu();
		});
    	
    	$('.mobile-menu-close').on('touchstart click', function () {
    		toggleMenu();
		});
    	
    	$('.sub-item-toggle').on('touchstart click', function () {
    		var subMenu = $(this).parents('.sub-nav').find('.sub-nav-list');
    		toggleSubNav(subMenu);
    	});
    	$('.sub-item-back').click(function() {
    		var subMenu = $(this).parents('.sub-nav').find('.sub-nav-list');
    		toggleSubNav(subMenu);
    	});
    	
    	$('.nav-item').hover(function () {
    		if($(this).hasClass('sub-nav')) {
    			var subNav = $(this).children('.sub-nav-list');
    			subNav.position({
    	        	my: 'bottom',
                    at: 'bottom',
                    of: subNav,
                    collision: 'flipfit'
    	        });
    		}
    	});
        $('.nav-item').on('keydown',function (e) {
            var key = e.which;
            if(key == 32)  {// the space key code
                e.preventDefault();
                if ($(this).hasClass('hover')) {
                    $(this).removeClass('hover');
                    $(this).attr('aria-expanded', false);
                } else {
                    $('.nav-item').removeClass('hover').attr('aria-expanded', false);
                    $(this).addClass('hover');
                    $(this).attr('aria-expanded', true);
                }
                //Trigger hover for keyboard accessibility
                return false;  
            }
        }); 
        	
    	$(window).resize( function (){
    		showNav();
    		setMediaType();
    	});
    	
    	// Initialize Widgets
        contact.init();
        validator.init();
        emailsignup.init();
        
        // Phone Links
        if ($('#main').find('.phone-link').length > 0 && $(window).width() > 960) {
        	$('.phone-link').removeAttr('href');
        }
        
        // Event Button
        var $els = document.querySelectorAll('.corp-event-button');
        $els.forEach(function ($el){
        	var evt = function(evt){ 
        		evt.preventDefault();
        		evt.currentTarget.dispatchEvent(createEvent(evt.currentTarget.dataset.event))
        	};
        	$el.addEventListener('touchend', evt, false);
        	$el.addEventListener('click', evt, false);
        })
        
        // Search
        var $searchContainer = $('.search-flyout');
        searchsuggest.init($searchContainer.find('.search-flyout-content'), Resources.SIMPLE_SEARCH);
        
    	// Accessibility
    	window.addEventListener('keydown', handleFirstTab);

        //sticky nav
        var onScroll = function() {

            var scrollTop = $(window).scrollTop();
            var headerHeight = $('header').outerHeight();
            
            if(scrollTop >= headerHeight) {                
                $('header').addClass('header-sticky');
                $('#wrapper').css('padding-top', headerHeight);
            } else {
                $('header').removeClass('header-sticky');
                $('#wrapper').css('padding-top', 0);
            }        
        }
        $(window).on('scroll', onScroll);
        onScroll();

        $('.menu-mask').on('click',function () {       
            toggleMenu();
        });
        $('.nav-item.where-to-buy-modal').on('click',function (e) {       
            e.preventDefault();
           $('#wtb-popup').dialog({
                autoOpen: true,
                width: 1100,
                modal: true,
                resizable: false,
                draggable: false,
                dialogClass: 'emailsignupform',
                position: {
                    my: 'center',
                    at: 'center',
                    of: window,
                    collision: 'flipfit'
                }
            });           
        });

        if ($('#back-to-top').length) {
            $('#back-to-top').on('click', function (e) {
                e.preventDefault();                
                $('html, body').animate({
                    scrollTop: 0 
                }, 1000, function() {
                    $('body').find(':focusable').eq(0).focus();
                });       
            });
        }

        if ($(window).width() > 959) {

            $('.country-selector').hoverIntent({
                sensitivity: 3, // number = sensitivity threshold (must be 1 or higher)
                interval: 100, // number = milliseconds for onMouseOver polling interval
                timeout: 500, // number = milliseconds delay before onMouseOut
                over: function() {
                    $('.country-selector .selector').slideDown(function(){
                        $(this).attr('aria-hidden', false);
                    });
                        
                },
                out: function() {
                    $('.country-selector .selector').slideUp(function(){
                        $(this).attr('aria-hidden', true);
                    });
            
                }
            });
        } else {
            $('button.country-select').on('click',function () {
                if ($(this).hasClass('open')) {
                    $('.country-selector .selector').slideUp(function(){
                         $(this).attr('aria-hidden', true);
                    });
                    $(this).removeClass('open');
                } else {
                    $('.country-selector .selector').slideDown(function(){
                        $(this).attr('aria-hidden', false);
                    });   
                    $(this).addClass('open');            
                }
            });
        }
    }
};

function toggleMenu() {
	$('body').toggleClass('no-scroll');
    $('header').toggleClass('menu-active');
	$('.mobile-menu-toggle').toggleClass('open');
	$('.sub-nav-list').removeClass('open');
	$('.sub-nav-list').prop('style', '');


    //close country selector if open
    $('.country-selector .selector').hide().attr('aria-hidden', true);
    $('button.country-select').removeClass('open');

	// Accessibility focus
	if ($('.mobile-menu-toggle').hasClass('open')) {
		$('.header-sticky').find('.nav-mobile-header a, .nav-list .nav-item a').first().focus();
	} else {
		$('.header-sticky').find('.mobile-menu-toggle').focus();
	}	
}

function toggleSubNav(subMenu) {
	subMenu.toggle('slide', {
        direction: 'left'
    }, 300);	
	subMenu.toggleClass('open');
	
	// Accessibility focus
	if ($('body').hasClass('user-is-tabbing')) {
		if (subMenu.hasClass('open')) {
			$('.header-sticky').find('.sub-nav-list.open ul').focus();
		} else {
			$('.header-sticky').find('.nav-list').focus();
		}
	}
}

function showNav() {
	if ($(window).width() > 960) {
		$('#navigation').prop('style', '');
		$('.sub-nav-list').prop('style', '');		
		$('.sub-nav-list').removeClass('open');	
        $('header').removeClass('menu-active');
        $('body').removeClass('no-scroll');
	}
}

//Accessibility
function handleFirstTab(e) {
    if (e.keyCode === 9) {
        document.body.classList.add('user-is-tabbing');
    
        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
}
function handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing');
  
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
}

function setCookie(key, value, minutes) {
    var date = new Date();
    date.setTime(date + (minutes * 60 * 1000));

    document.cookie = key + '=' + encodeURIComponent(value) + '; expires=' + date.toGMTString() + '; path=/';
}

// initialize app
$(document).ready(function() {
    app.init();
});
